import { useWebStore } from "~/store/WebStore";
export default defineNuxtRouteMiddleware((to) => {
    const webStore = useWebStore();
    
    const selectedStore = computed(() => webStore.selectedStore)
    if (selectedStore.value) {
        to.meta.layout = 'store';
    } 
    // else {
    //     to.meta.layout = 'default';
    // }
});
